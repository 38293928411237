<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import type { InputsGoogleAutocompleteProps } from './InputsGoogleAutocomplete.props'
import type { AddressGoogleAutocomplete } from './InputsGoogleAutocomplete.types'
import type { FormKitNode } from '@formkit/core'
import { supportedLanguageCodes } from './InputsGoogleAutocomplete.supportedLanguages'

const ENTER_EVENT = {
  key: 'Enter',
  code: 'Enter',
  which: 13,
  keyCode: 13,
  charCode: 13,
}

const ARROW_DOWN_EVENT = {
  key: 'ArrowDown',
  code: 'ArrowDown',
  which: 40,
  keyCode: 40,
  charCode: 40,
}

const props = defineProps<InputsGoogleAutocompleteProps>()
const emit = defineEmits<{
  (e: 'update:modelValue', value: string): string
  (e: 'searched', value: google.maps.places.PlaceResult): void
  (e: 'click-action-button'): void
}>()
const { country } = useRouteHelper()
const isJp = computed(() => country.toUpperCase() === 'JP')

const { onLoaded } = useScriptGoogleMaps({
  apiKey: useRuntimeConfig().public.google,
  //@ts-ignore patched node_modules doens't recognize language
  language: supportedLanguageCodes.includes(props.language ?? 'en')
    ? props.language
    : 'en',
})

const forkmitGoogleAutocompleteRef = ref<null | { node: FormKitNode }>(null)
const autocomplete = ref<HTMLInputElement>()
//Google Maps Autocomplete API doesn't have types
const autocompleteInstance: any = ref(null)

const value = ref('')
const fullAddressValue = ref<AddressGoogleAutocomplete>()

const getAutoComplete = () => {
  if (autocompleteInstance.value) {
    autocompleteInstance.value.addListener('place_changed', () => {
      value.value = ''
      const place = autocompleteInstance.value
        ? autocompleteInstance.value.getPlace()
        : null

      value.value = place.name
      fullAddressValue.value = place
      const autocompleteFormkitNode = forkmitGoogleAutocompleteRef.value?.node
      autocompleteFormkitNode?.extend('fullAddressValue', place)
      if (place) {
        emit('searched', place)
      }
    })
  }
}

//Accessibility methods
const enterClick = () => {
  const enterEvent = new KeyboardEvent('keydown', ENTER_EVENT)
  if (autocomplete.value) {
    autocomplete.value.dispatchEvent(enterEvent)
  }
}
onKeyStroke('Enter', e => {
  e.preventDefault()
})

/*
    Formkit and the input inside his template #input are not linked by a v-model so we need to manually change
    the value ref every time the input change. On the other hand for the FormKit component we only need the
    v-model to the value for Formkit error handling to kick in.
*/
const assignValue = (event: any) => {
  value.value = event.target?.value satisfies string
}

const deleteValue = () => {
  forkmitGoogleAutocompleteRef.value?.node.reset()
  if (autocomplete.value?.value) {
    autocomplete.value.value = ''
  }
}

const validationRuleHouseNumber = (node: any) => {
  //Rework by design needed, this is a temporary comment
  // const fullAddress = node.traps.get(
  //   'fullAddressValue'
  // ) as AddressGoogleAutocomplete

  return /\d/.test(node.value as any)
}

onMounted(() => {
  onLoaded(async instance => {
    const maps = (await instance.maps) as any as typeof google.maps // upstream google type issue
    if (!autocomplete.value) return
    autocompleteInstance.value = new maps.places.Autocomplete(
      autocomplete.value,
      {
        componentRestrictions: {
          country: props.restrictToCountry ?? null,
        },
      }
    )
    getAutoComplete()
  })
})

// Simulate arrow down + enter for autocomplete
const simulateArrowDownPlusEnter = () => {
  const arrowDownEvent = new KeyboardEvent('keydown', ARROW_DOWN_EVENT)
  const enterEvent = new KeyboardEvent('keydown', ENTER_EVENT)

  if (autocomplete.value) {
    autocomplete.value.dispatchEvent(arrowDownEvent)
    autocomplete.value.dispatchEvent(enterEvent)
  }
}
</script>

<template>
  
<FormKitLazyProvider config-file="true">
<FormKit
    id="googleAutocomplete"
    ref="forkmitGoogleAutocompleteRef"
    v-model="value"
    :label="
      isJp
        ? $ts('checkout.shipping.form.zip_code')
        : $ts('checkout.shipping.form.line_1')
    "
    name="line_1"
    validation="required|validationRuleHouseNumber"
    :validation-rules="{ validationRuleHouseNumber }"
    :validation-messages="{
      required: $ts('fieldRequired'),
      validationRuleHouseNumber: $ts(
        'checkout.shipping.form.houseNumberValidationMessage'
      ),
    }"
    :floating-label="true"
    :classes="{
      outer: 'relative',
      label: 'pointer-events-none',
    }"
    type="text"
    role="presentation"
    autocomplete="off"
  >
    <template #input>
      <input
        id="googleAutocomplete"
        ref="autocomplete"
        class="text-book-6 text-primitives-black -ml-2 h-5 w-[Calc(100%_+_8px)] appearance-none border-none bg-transparent pl-2 focus:shadow-none focus:outline-none focus:ring-0"
        :class="[showRemoveButton && value ? 'pr-24' : 'pr-16']"
        type="text"
        role="presentation"
        autocomplete="off"
        placeholder=""
        :aria-label="$ts('checkout.shipping.form.line_1')"
        aria-labelledby="googleAutocomplete"
        @keyup.enter.stop="enterClick"
        @input="assignValue"
      />
    </template>
    <template #messages>
      <div v-if="actionText" class="mt-2 flex justify-end">
        <AtomsCta
          v-if="actionText"
          anatomy="link"
          class="!text-link-8"
          @click="emit('click-action-button')"
        >
          <template #label>
            {{ actionText }}
          </template>
        </AtomsCta>
      </div>
    </template>
    <template #suffixIcon>
      <div class="absolute right-0 flex items-center gap-x-2 pr-2">
        <button
          type="button"
          class="text-medium-7 disabled:text-primitives-grey-150 uppercase"
          :disabled="!value"
          :aria-label="$ts('accessibility.search') + ' ' + value"
          @click.stop="simulateArrowDownPlusEnter"
          @keydown.enter.stop="simulateArrowDownPlusEnter"
        >
          {{ $ts('aria-labels.search.button') }}
        </button>

        <button
          v-if="showRemoveButton && value"
          type="button"
          :aria-label="$ts('accessibility.clearAddress')"
          @click.prevent="deleteValue"
          @mousedown.prevent
        >
          <DefaultIconsClose
            class="text-icon-tertiary h-5 w-5"
            aria-hidden="true"
          />
        </button>
      </div>
    </template>
  </FormKit>
</FormKitLazyProvider>

</template>
